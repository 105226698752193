import { dynamicsObject } from "@/interfaces";
import { File } from "@/interfaces/file/file.dto";

export const isCreater = (creater: string, user: string) => {
  return creater === user;
}

export const focusInputElement = (input: HTMLInputElement) => {
  input.focus();
}

export const blurInputElement = (input: HTMLInputElement) => {
  input.blur();
}

export const refreshProperties = (_old: dynamicsObject, _new: dynamicsObject, variables: Array<string>) => {
  for (const variable of variables) {
    _old[variable] = _new[variable];
  }
}

export function hexToRgb(hex: string) {
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + "," + g + "," + b;
}

export function ObjectClearRef(data: dynamicsObject) {
  return JSON.parse(JSON.stringify(data));
}