export enum ROLES_NAMES {
  OWNER = 'Владелец',
  USER = 'Пользователь',
}

export enum TARIFF_NAMES {
  DEFAULT = 'Обычный',
  DISPOSABLE = 'Одноразовый',
}

export enum SUBSCRIBER_NAMES {
  ACTIVE = 'Активный',
  UNACTIVE = 'Не активный',
  VIEW = 'Просмотрел',
  KNOCKED = 'Постучался',
  BANNED = 'Заблокирован',
}

export enum TRANSACTION_NAMES {
  SUBSCRIBE = 'Оплата подписки',
  EXTENDED_SUBSCRIBE = 'Продление подписки',
  WITHDRAWAL = 'Вывод средств',
  WITHDRAWAL_PARTNER = 'Вывод партнерских средств',
  REPLENISHMENT = 'Пополнение баланса',
  PARTNER_BONUS = 'Партнерский бонус',
  PAYMENT_TARIFF = 'Списание за подписчика',
  BONUS = 'Бонус',
  BURNED_BONUS = 'Сгорание бонуса',
}

export enum LEVEL_NAMES {
  FIRST = 'Первый уровень',
  SECOND = 'Второй уровень',
}

export enum ORDER_NAMES {
  PAID = 'Выплачено',
  UNPAID = 'Не выплачено',
}

export enum TRANSACTION_PAYMENT_METHOD_NAMES {
  YANDEX = 'Yoomoney',
  TINKOFF = 'Tinkoff',
  BOTPAY = 'Botpay',
  FREE = 'Бесплатно',
}