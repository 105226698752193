
import { defineComponent } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { STATUS_DROP } from "@/store/common/default/modal";
import { useAPI } from "@/use";
import { ObjectClearRef } from "@/store/common";
import Statuses from './statuses/index.vue';
import Subscribers from './subscribers/index.vue';

export default defineComponent({
  name: "ModalRecipient",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
    }
  },
  created() {
    this.checkStatuses();
    if (this.data.channel_id)
      this.getSubscriberListMethod();
  },
  watch: {
    'data.channel_id'() {
      this.getSubscriberListMethod();
    },
  },
  methods: {
    async getSubscriberListMethod() {
      this.pending = true;
      const result = await useAPI().mailing.getSubscriberListMethod(this.data.channel_id);
      result.data.forEach((el: { selected: boolean, _id: string }) => el.selected = this.data.subscribers.includes(el._id));
      this.data.subscribers = result.data;
      this.pending = false;
    },
    checkStatuses() {
      const statuses = (ObjectClearRef(STATUS_DROP) as Array<dynamicsObject>);
      statuses.forEach(s => s.selected = this.data.statuses.includes(s._id));

      this.data.statuses = statuses;
    },
    resetSubscribers() {
      this.data.subscribers.forEach((s: { selected: boolean }) => s.selected = false);
    }
  },
  computed: {
    isSubscribersChannel() {
      return !!this.data.subscribers.length;
    },
  },
  components: {
    Statuses,
    Subscribers
  }
});
